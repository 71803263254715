.App {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    background: aquamarine;

    h1, h2, h3, h4, h5, h6 {
        margin: 0;
        padding: 0;
    }

    .main_form_wrapper {
        width: 100%;
        height: 100%;

        #AppForm {
            width: 100%;
            height: 70%;
            .form_wrapper {
                display: flex;
                flex-direction: row;
                justify-content: center;
                .text {
                    width: 30%;
                    margin-right: 5%;

                    &:after {
                        content: "";
                        background-color: #000000;
                        position: absolute;
                        width: 2px;
                        height: 30%;
                        top: 30%;
                        left: 50%;
                        display: block;
                    }
                }
                .actual_form {
                    margin-left: 5%;
                    width: 30%;
                }
                .alertDiv {
                    display: flex;
                    flex-direction: column;
                    .alert_wrapper {
                        @media only screen and (max-width: 600px) {
                            .alert-heading.h4 {
                                font-size: 1.2em !important;
                            }
                        }
                    }
                }
            }
        }

        #AppInfo {
            width: 100%;
            height: 30%;
            justify-content: center;
            display: flex;
            align-content: center;
            align-items: center;
            h2 {
                margin: 2% 5%
            }
        }
    }

    .home_wrapper {
        width: 100%;
        .welcome, .user_start {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-top: 20%;
            @media only screen and (max-width: 600px) {
                margin-top: 70%;
            }
        }
        .buttons {
            width: 20%;
            @media only screen and (max-width: 600px) {
                width: 50%;
            }
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            margin-top: 5%;
        }
        .pair_result {
            margin-top: 2%;
            .buttons {
                width: 100%;
            }
        }
    }
}
